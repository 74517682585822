import React from 'react'
import logo from '../imagenes/halias-logo.png';
import NavBar from "./NavBar";
import {NavLink} from 'react-router-dom'
import Dolar from './Dolar';
import Clima from './Clima';
import Hamburger from './Hamburger';
import Login from './Login';
import CartWidget from './CartWidget';

const Header = () => {

    return (
        <div className="header_container">
            <div className="header_widgets">
                <div>
                    <Dolar/>-<Clima/>
                </div>
                <div>
                    <Login/>
                </div>
            </div>
            <header className="header">
                <NavLink exact to="/"><img src={logo} alt="halias" width="120" className="logo"/></NavLink>
                <div className="container_nav">
                    <NavBar />
                    <div className="icon">
                        <NavLink to="/carrito" title="Carrito"><CartWidget/></NavLink>
                    </div>
                    <button>Menu</button>
                    <Hamburger/>
                </div>
            </header>
        </div>
    )
}

export default Header
