import firebase from 'firebase/app'
import 'firebase/firestore'

const miConfigFirebase = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGINSENDERID,
    appId: process.env.REACT_APP_APPID
}


const app = firebase.initializeApp(miConfigFirebase)

export const firestore = firebase.firestore(app)

export default firebase