import React, {useContext, useEffect, useState} from 'react'
import {motion} from 'framer-motion'
import LoginContext from './LoginContext'
import PedidosContext from './PedidosContext'

const MiCuenta = () => {

    const { avatar, usuario, ingreso, nombre, email, creacion, login, logout } = useContext(LoginContext)
    const { info, pedidos, idActual, setIdActual, editarPedido } = useContext(PedidosContext)


    return (
        <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="intro">
            { ingreso === true ? (  
                <div>
                    <h3>Hola {nombre}</h3> <button onClick={logout}>Salir</button>
                    <p>{email}</p>
                    <p>Usuario desde: {creacion}</p>
                    <p>Pedidos:</p>
                    <ul className="listado_pedidos">
                    { info.length > 0 ? (  
                    info.map((pedido, i) =>{
                        return (
                            <li key={pedido.id} className={pedido.id}>
                                <div className="pedidos">
                                    <div>
                                        Pedido N° {i + 1} - {new Date(pedido.date.seconds * 1000).toLocaleDateString("es-ES")}
                                    </div>
                                    <div>
                                        Productos:
                                        {pedido.items.map((item, i) => (
                                            <div key={"producto_" + (i + 1)}>{i + 1}- {item.item.title} - cant: {item.contador}</div>
                                        ))}
                                    </div>
                                    <div>
                                        Total: u$d {pedido.total}<br/>
                                    </div>
                                </div>
                            </li>
                            );
                        })
                    )
                    : (
                        <p className="advertencia">Cargando tus pedidos.</p>
                    )
                    }
                    </ul>
                </div>
                )
                : (
                <button onClick={login}>Ingresar</button>
                )
            }

        </motion.div>
    )
}

export default MiCuenta
