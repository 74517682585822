import React, {useEffect,useState} from 'react'
import axios from 'axios'
import {firestore} from '../firebaseConfig'
import firebase from 'firebase/app';
import {useHistory} from 'react-router-dom'

import {motion} from 'framer-motion'

const Hubspot = () => {

const [formulario, setFormulario] = useState("mostrar")
const [nombre, setNombre] = useState("")
const [apellido, setApellido] = useState("")
const [email, setEmail] = useState("")
const [confirmacion, setConfirmacion] = useState("")
const [telefono,setTelefono] = useState("")
const [bloqueo,setBloqueo] = useState(true)
const [alerta, setAlerta] = useState("")

const obtenerNombre = (e) => {
    setNombre(e.target.value)
    console.log(nombre)
}
const obtenerApellido = (e) => {
    setApellido(e.target.value)
    console.log(apellido)
}
const obtenerEmail = (e) => {
    setEmail(e.target.value)
    console.log(email)
}
const confirmarEmail = (e) => {
    console.log(confirmacion)
    setConfirmacion(e.target.value)
        if (e.target.value === email) {
            console.log("el correo coincide")
            setBloqueo(false)
            setAlerta("Perfecto!!!")
        } else {
            console.log("el correo no coincide")
            setBloqueo(true)
            setAlerta("Ups... Tu correo no coincide")
        }
}

const obtenerTelefono = (e) => {
    setTelefono(e.target.value)
    console.log(telefono)
}

   // Clave API 
   let api_halias = process.env.REACT_APP_HUBSPOT_API;
   let crear_contacto = "https://api.hubapi.com/contacts/v1/contact/createOrUpdate/email/:" + email + "/?hapikey=" + api_halias;
   let obtener_contacto = "https://api.hubapi.com/contacts/v1/lists/all/contacts/all?hapikey="+ api_halias;
   let envio_formularios = "https://api.hsforms.com/submissions/v3/integration/submit/7748833/a8fb3d3d-df99-413d-8f65-0ad4d65776b4";

   useEffect(() => {        

   /*
   const obtengo_contactos = axios.get(obtener_contacto,{
       method: 'GET',
       headers: {
           'Access-Control-Allow-Origin': '*',
           'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
           'Authorization': 'Bearer e60a551f-7ea4-4dc4-8e07-de513aa50239',
           'Content-Type': 'application/json; charset=utf-8'
       }
   })
   .then(resp => {
   console.log(resp);
   })
   .catch(error => {
   console.log(error);
   })
   */


   }, []);



let history = useHistory()

const enviarOrden = (e) => {
    e.stopPropagation()
    e.preventDefault()
    console.log("click " + nombre + email + telefono)

    // Envío la orden a la collección de firebase
    /*
    const db = firestore
    const orders = db.collection('ordenes')

    const order = {
        buyer: { name: nombre, email: email, phone: telefono},
        date: firebase.firestore.Timestamp.fromDate(new Date()),
    }
    orders.add(order)
    .then(({ id }) => {
        console.log("Orden enviada con éxito")
        setNombre("")
        setTelefono("")
        setEmail("")
        setConfirmacion("")
        setFormulario("ocultar")
    })
    .catch((error) => console.log(error))
    */

    
    const creo_contacto = axios.post(crear_contacto,{
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin':  'http://localhost:3000/',
            'Access-Control-Allow-Methods': 'POST',
            'Access-Control-Allow-Headers': 'Content-Type, Authorization'
        },
        body: {
            "properties": [
                {
                "property": "email",
                "value": email
                },
                {
                "property": "firstname",
                "value": nombre
                },
                {
                "property": "lastname",
                "value": apellido
                },
                {
                "property": "phone",
                "value": telefono
                }
            ]
            }
    })
    .then(resp => {
         console.log(resp.data)
         console.log(resp.data.inlineMessage)
         console.log("envio a hubspot" + resp);
         console.log(creo_contacto)
         console.log(creo_contacto.body)
    })
    .catch(error => {
         console.log(error);
    })
}

 

    return (
        <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="intro">
            <form className={formulario}>
                    <fieldset className="fieldset_2">
                        <div>
                            <label>Nombre</label>
                            <input onChange={obtenerNombre} type="text" name="firstname" value={nombre} required/>
                        </div>
                        <div>
                            <label>Apellido</label>
                            <input onChange={obtenerApellido} type="text" name="lastname" value={apellido} required/>
                        </div>
                    </fieldset>
                    <fieldset className="fieldset_2">
                        <div>
                            <label>Correo</label>
                            <input onChange={obtenerEmail}type="email" name="email" value={email} required/>
                        </div>
                        <div>
                            <label>Confirme su correo</label>
                            <input onChange={confirmarEmail}type="email" name="" value={confirmacion} required/>
                        </div>
                        <small>{alerta}</small>
                    </fieldset>
                    <fieldset className="fieldset_1">
                        <div>
                            <label>Teléfono</label>
                            <input onChange={obtenerTelefono}type="tel" name="phone" value={telefono}  required/>
                        </div>
                    </fieldset>
                    <fieldset className="fieldset_1">
                        <div>
                            <label>¿Algún otro comentario?</label>
                            <textarea name="message"></textarea>
                        </div>
                        <div>
                            <label>¿Quieres suscribirte a nuestro Newslleter?</label>
                            <div className="input_suscripcion">
                                <input className="toggle-state" type="checkbox" name="check" value="check"/>
                                <label>Sí, quiero suscribirme.</label>
                            </div>
                        </div>
                    </fieldset>
                    <div>
                        <input type="submit" value="Enviar pedido" onClick={enviarOrden} disabled={bloqueo}/>
                    </div>  
                </form>
        </motion.div>
    )
}

export default Hubspot
