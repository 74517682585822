import React from 'react';
import ItemListContainer from './ItemListContainer';
import {Route, Switch, useLocation} from 'react-router-dom'
import Intro from './Intro'
import MiCuenta from './MiCuenta'
import ItemDetailContainer from './ItemDetailContainer';
import Elementos from './Elementos'
import Contacto from './Contacto';
import Blog from './Blog';
import Cart from './Cart';
import BuyCart from './BuyCart';
import Hubspot from './Hubspot';
import EnergiaPensada from './EnergiaPensada';
import { AnimatePresence } from 'framer-motion';
import Residencial from './Residencial';
import Empresas from './Empresas';
import Pedidos from './Pedidos';


const Main = ({productos}) => {
    const location = useLocation()
    return (
        <main>
            <AnimatePresence initial={false} exitBeforeEnter>
            <Switch location={location} key={location.key}>
                    <Route exact path="/" key="home">
                        <Intro titulo="halias" subtitulo="Energía pensada para reducir costos y aumentar valor." bajada="Calefacción eficiente, calderas, radiadores, piso radiante, bombas de calor y energía solar. Encontrá todo acá."/>
                        <ItemListContainer greeting="Filtra tus productos preferidos" listaProductos={productos}/>
                    </Route>
                    <Route exact path="/productos/:url" key="productos">
                        <ItemDetailContainer listaProductos={productos}/>
                    </Route>
                    <Route exact path="/categorias/:id" key="categorias">
                        <ItemListContainer greeting="Filtra tus productos preferidos" listaProductos={productos}/>
                    </Route>
                    <Route path="/elementos" key="elementos">
                        <Elementos/>
                    </Route>
                    <Route path="/carrito" key="carrito">
                        <Cart/>
                    </Route>
                    <Route path="/mi-cuenta" key="mi-cuenta">
                        <Intro titulo="mi cuenta"/>
                        <MiCuenta/>
                    </Route>
                    <Route path="/contacto" key="contacto">
                        <Intro titulo="contacto" subtitulo="estamos para ayudarte" bajada="Mensajeanos, visitanos o chatea con nosotros."/>
                        <Contacto/>
                    </Route>
                    <Route path="/finalizar-compra" key="finalizar-compra">
                        <BuyCart/>
                    </Route>
                    <Route path="/hubspot" key="hubspot">
                        <Intro/>
                        <Hubspot/>
                    </Route>
                    <Route path="/energia-pensada" key="energia-pensada">
                        <Intro titulo="energía pensada" subtitulo="Servicio Integral Profesional en Energía Solar y Climatización" bajada="Obetné mejores rendimientos en tu hogar o empresa."/>
                        <EnergiaPensada/>
                    </Route>
                    <Route exact path="/residencial" key="residencial">
                        <Intro titulo="energía pensada residencial" subtitulo="Servicio Integral Profesional en Energía Solar y Climatización" bajada="Obetné mejores rendimientos en tu hogar o empresa."/>
                        <Residencial/>
                    </Route>
                    <Route exact path="/empresas" key="empresas">
                        <Intro titulo="energía pensada para empresas" subtitulo="Servicio Integral Profesional en Energía Solar y Climatización" bajada="Obetné mejores rendimientos en tu hogar o empresa."/>
                        <Empresas/>
                    </Route>
                    <Route path="/blog" key="blog">
                        <Intro titulo="blog" subtitulo="Un blog de Sustentabilidad y Eficiencia Energética"/>
                        <Blog/>
                    </Route>
                    <Route path="/pedidos" key="pedidos">
                        <Intro titulo="pedidos" />
                        <Pedidos/>
                    </Route>
            </Switch>
            </AnimatePresence>
        </main>
    )
}

export default Main
