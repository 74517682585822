import {createContext} from 'react'



// Contexto: Patrón de diseño de Publicación y Subscripción

const LoginContext = createContext()


//export const {Consumer,Provider} = Contexto
export default LoginContext
