import React, {useState,useEffect} from 'react'
import CartContext from './CartContext'


const CartState = ({children}) => {

   const [cart,setCart] = useState([])
   const [total, setTotal] = useState(0)
   const [cantItems, setCantItems] = useState(0)
   //const [actualizar,setActualizar] = useState(0)
   
   const addToCart = ({item, contador, precio, id}) => {
       let productoExistente = cart.find(producto => producto.item.id === id)
       if (productoExistente) {
           console.log("Este producto ya existe")
           console.log(productoExistente.contador)
           console.log("agregarndo " + contador + " unidad/es al producto ID: " + id + " ya existente")
           setCart([
               cart.find(producto => producto.item.id !== id),
               {
                   item: item,
                   contador: productoExistente.contador + contador,
                   precio: precio
                }
            ])
        } else {
            setCart([
                ...cart,
                {
                    item: item,
                    contador: contador,
                    precio: precio
                }
            ])
        }

        let products = [];
        products.push(
            ...cart,
            {
                item: item,
                contador: contador,
                precio: precio
            }
        );
        sessionStorage.setItem('Carrito', JSON.stringify(products))

    }
    
    const clearItem = () => {
        sessionStorage.removeItem('Carrito')
        setCart([])
    }
    const removeItem = id => {
        const nuevoProducto = cart.filter(producto => producto.item.id !== id)
        setCart(nuevoProducto)
        sessionStorage.setItem('Carrito', JSON.stringify(nuevoProducto));
    }
    
    
    useEffect(() => {
        
        let totalActual = 0
        const totales = cart.map( producto => producto.item.price * producto.contador )
        totales.map( totalPagar => totalActual += totalPagar)
        setTotal(totalActual)
        
        let totalCarrito = 0
        let cantidadItems = cart.length
        console.log("Cantidad de productos: " + cantidadItems)
        let cantidadTotales = cart.map( producto => producto.contador)
        cantidadTotales.map(t => totalCarrito += t)
        console.log(totalCarrito)
        setCantItems(totalCarrito)

        setTimeout(() => {
            if(sessionStorage.getItem('Carrito') != null){
                //setCart([JSON.parse(sessionStorage.getItem('products'))])
                console.log("Obtengo el carrito de la sesión")
                console.log(JSON.parse(sessionStorage.getItem('Carrito')))
                let objetos = JSON.parse(sessionStorage.getItem('Carrito'))
                if (cart < 1) {
                    setCart(objetos)
                }
            }
        },500)
        
    }, [cart])
    
    

    return (
        <CartContext.Provider value={{cart,total,addToCart,clearItem,removeItem, cantItems, setCart }}>
            {children}
        </CartContext.Provider>
    )
}

export default CartState
