import React, {useContext,useState} from 'react'
import {Link} from 'react-router-dom'
import bookmark from '../imagenes/bookmark.svg'
import {motion, AnimatePresence} from 'framer-motion'
import DolarContext from './DolarContext'


let transition = {duration: 0.6, ease: [0.43, 0.13 , 0.23, 0.96]}

const Item = ({title,identificador,price,imagen,url}) => {  

    function stopDefAction(evt) {
        // evt.preventDefault();
    }

    const { dolar } = useContext(DolarContext)


    return (
            <li className="item">  
                <Link to={"/productos/" + url} title={title}>
                    <div className="imagen"><motion.img whileHover={{scale:1.1}} src={imagen} alt={title} transition={transition}/></div>
                    <motion.div 
                    exit={{ opacity: 0 }}
                    transition={{transition}}
                    className="detalles">
                        <h2>{title}</h2>
                        <p>u$d {price}</p>
                        <small>ID: {identificador}</small>
                    </motion.div>
                    <span className="bockmark"><img src={bookmark} alt="bookmark" width="20px"/></span>
                </Link>
            </li>
    )
}

export default Item
