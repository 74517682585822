import React, {useContext} from 'react'
import NavBarFooter from './NavBarFooter'
import {NavLink} from 'react-router-dom'
import LoginContext from './LoginContext'

const Footer = () => {

    const { usuario, ingreso, email, login } = useContext(LoginContext)


    return (
        <footer>
            <div className="footer">
                <div className="footer_column">
                    <h3>Enlaces de interés:</h3>
                    <NavBarFooter/>
                    <ul>
                        <li className="icon">
                            <NavLink to="/elementos">Elementos</NavLink>
                        </li>
                        <li className="icon">
                            <NavLink to="/contacto" title="Contacto">Contacto</NavLink>
                        </li>
                        <li className="icon">
                            <NavLink to="/blog" title="Blog">Blog</NavLink>
                        </li>
                        <li className="icon">
                            <NavLink to="/hubspot" title="Hubspot">Hubspot</NavLink>
                        </li>

                        {(() => {
                            switch (email) {
                            case "info@halias.com.ar":   return (
                                <li className="icon">
                                    <NavLink to="/pedidos" title="Pedidos">Pedidos</NavLink>
                                </li>
                            )
                            case "agustin@halias.com.ar": return (
                                <li className="icon">
                                    <NavLink to="/pedidos" title="Pedidos">Pedidos</NavLink>
                                </li>
                            )
                            case "contacto@halias.com.ar":  return (
                                <li className="icon">
                                    <NavLink to="/pedidos" title="Pedidos">Pedidos</NavLink>
                                </li>
                            )
                            default:      return "";
                            }
                        })()}
                    </ul>
                </div>
            </div>
            <small className="copy">Halias &copy; | 2021 - Todos los derechos reservados</small>
        </footer>
    )
}

export default Footer