import React from 'react'
import empresas from '../imagenes/empresas.jpg'
import {motion} from 'framer-motion'


const Empresas = () => {

    let transition = {duration: 1, ease: [0.43, 0.13 , 0.23, 0.96]}


    return (
        <div className="banner_hero">
            <div
            className="img_transition_banner empresas">
            <motion.div 
            initial={{width: "250px", height: "100%" }}
            animate={{width: "100%", height: "400px", left: "0%", x: "0"}}
            transition={transition}
            exit={{opacity: 0}}
            className="clip_path_banner" style={{ backgroundImage: `url(${empresas})` }}></motion.div>
            </div>
        </div>
    )
}

export default Empresas
