import React from 'react'
import {Link} from 'react-router-dom'
import {motion} from 'framer-motion'
import residencial from '../imagenes/residencial.jpg'
import empresas from '../imagenes/empresas.jpg'


const EnergiaPensada = () => {

    const transition = {duration: 0.6, ease: [0.43, 0.13 , 0.23, 0.96]}

    return (
        <div className="flex">
                <div className="card_transition">
                <Link to="/residencial">
                    <motion.h3 exit={{opacity: 0}} transition={{transition}}>residencial</motion.h3>
                    <motion.div transition={{transition}} className="clip_path" style={{ backgroundImage: `url(${residencial})` }}></motion.div>
                </Link>
                </div>
                
                <div className="card_transition">
                <Link to="/empresas">
                    <motion.h3 exit={{opacity: 0}} transition={{transition}}>empresas</motion.h3>
                    <motion.div transition={{transition}} className="clip_path" style={{ backgroundImage: `url(${empresas})` }}></motion.div>
                </Link>
                </div>
                           
        </div>
    )
}

export default EnergiaPensada
