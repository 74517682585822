import React, {useEffect, useState, useContext} from 'react'
import LoginContext from './LoginContext'
import PedidosContext from './PedidosContext'
import {firestore} from '../firebaseConfig'

const PedidosState = ({children}) => {
        
    const { usuario, ingreso, email, login } = useContext(LoginContext)

    const [info,setInfo] = useState([]);
    const [idActual,setIdActual] = useState("")
    const [pedidos, setPedidos] = useState([])

    /*
    const obtenerPedidos = async () => {
        firestore.collection('ordenes').orderBy('date', 'desc').onSnapshot((querySnapshot) => {
            console.log(querySnapshot)
            const docs = [];
            querySnapshot.forEach((doc) => {
                console.log(doc.data());
                console.log(doc.id);
                docs.push({...doc.data(), id:doc.id});
            });

            console.log(docs)
            // Creo las diferencias en el usuario
            if (email === "info@halias.com.ar"){
                setInfo(docs);
            } else {            
                const pedidosUsuario = docs.filter(user => user.buyer.email === email);
                setInfo(pedidosUsuario);
            }

        });
    };
    */

    useEffect (() => {    
        
        firestore.collection('ordenes').orderBy('date', 'desc').onSnapshot((querySnapshot) => {
            const docs = [];
            querySnapshot.forEach((doc) => {
                docs.push({...doc.data(), id:doc.id});
            });
            // Creo las diferencias en el usuario
            if (email === "info@halias.com.ar"){
                setInfo(docs);
            } else {            
                const pedidosUsuario = docs.filter(user => user.buyer.email === email);
                setInfo(pedidosUsuario);
            }

        });


    }, [email]);
    
    
    const editarPedido = (id) => {
    
    }


    return (
        <PedidosContext.Provider value={{info, pedidos, idActual, setIdActual, editarPedido}}>
            {children}
        </PedidosContext.Provider>
    )
}

export default PedidosState
