import React from 'react'
import {motion} from 'framer-motion'

let transition = {duration: 0.6, ease: [0.43, 0.13 , 0.23, 0.96]}

const Home = ({titulo,subtitulo,bajada}) => {

    return (
        <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={transition}
        className="intro centrado">
            <h1>{titulo}</h1>
            <h2 className="aparecer_top">{subtitulo}</h2>
            <p className="aparecer_top">{bajada}</p>
        </motion.div>
    )
}

export default Home
