import React from 'react'
import {motion} from 'framer-motion'
import residencial from '../imagenes/residencial.jpg'

const Residencial = () => {

    let transition = {duration: 0.6, ease: [0.43, 0.13 , 0.23, 0.96]}


    return (
        <div className="banner_hero">
            <div           
            className="img_transition_banner residencial">
            <motion.div 
            initial={{width: "250px", height: "100%"}}
            animate={{width: "100%", height: "400px", left: "50%"}}
            transition={transition}
            exit={{opacity: 0}}
            className="clip_path_banner" style={{ backgroundImage: `url(${residencial})` }}></motion.div>
            </div>
        </div>
    )
}

export default Residencial
