import React, {useEffect, useState} from 'react'
import Header from "./components/Header"
import Footer from "./components/Footer"
import Main from './components/Main'
import {BrowserRouter} from 'react-router-dom'
import {firestore} from './firebaseConfig'
import {AnimatePresence, motion} from 'framer-motion'
import CartState from './components/CartState'
import DolarState from './components/DolarState'
import LoginState from './components/LoginState'
import PedidosState from './components/PedidosState'

const App = () => {

    // Hago el llamado a Firebase
    const [productos,setProductos] = useState([])

    useEffect(() => {
        const db = firestore
        const collection = db.collection("productos")
        //const query = collection.get()
        collection.get().then(querySnapshot=>{
            const items = (querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id})));
        setProductos(items)
            console.log("Firestore conectado correctamente")
        })
        .catch((err)=>{
            console.log("Fallo el llamado a Firebase" + err)
        })

    }, [])


    return(
        <BrowserRouter>
            <AnimatePresence initial={false} exitBeforeEnter>
                <CartState>
                    <DolarState>
                        <LoginState>
                            <PedidosState> 
                                <Header/>
                                <Main productos={productos}/>
                                <Footer/>
                            </PedidosState> 
                        </LoginState>
                    </DolarState>
                </CartState>
            </AnimatePresence>
        </BrowserRouter>
    )
}

export default App