import React, {useEffect,useState} from 'react'
import LoginContext from './LoginContext'
import firebase from '../firebaseConfig'
import 'firebase/auth';
import cuenta from '../imagenes/user.svg'


const LoginState = ({children}) => {

    const [ingreso,setIngreso] = useState(false)
    const [usuario, setUsuario] = useState()
    const [avatar,setAvatar] = useState()
    const [nombre, setNombre] = useState() 
    const [email,setEmail] = useState()
    const [creacion, setCreacion] = useState()
  
    
    const login = () => {
        let provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth()
        .signInWithRedirect(provider)
    }

    useEffect(() => {

        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
              console.log('Usuario logueado correctamente');
              ((user.photoURL != null)
              ? setAvatar(user.photoURL)
              : setAvatar(cuenta)
              )
              setIngreso(true)
              setUsuario(user)
              setNombre(user.displayName);
              setEmail(user.email)
              setCreacion(user.metadata.creationTime)
            } else {
                console.log('Usuario deslogueado');
                setAvatar(cuenta)
                setIngreso(false)
                setUsuario()
                setNombre();
                setEmail()
                setCreacion()
            }
        })

        firebase.auth()
        .getRedirectResult()
        .then((result) => {
            console.log(result.credential);
            var credential = result.credential;
            var token = credential.accessToken;
            var user = result.user;
            ((user.photoURL != null)
            ? setAvatar(user.photoURL)
            : setAvatar(cuenta)
            )
            setIngreso(true)
            setUsuario(user)
            setNombre(user.displayName);
            setEmail(user.email)
            setCreacion(user.metadata.creationTime)
        }).catch((error) => {
            var errorCode = error.code;
            var errorMessage = error.message;
            var email = error.email;
            var credential = error.credential;
        })

    },[usuario])
    
    const logout = () => {
        firebase.auth().signOut().then(function() {
        // Sign-out successful.
        }).catch(function(error) {
        // An error happened.
        });
}


    return (
        <LoginContext.Provider value={{ingreso, usuario, avatar, nombre, email, creacion, login, logout}}>
            {children}
        </LoginContext.Provider>
    )
}

export default LoginState
