import React, {useEffect,useState} from 'react'
import axios from "axios";

const Clima = () => {

    const [clima, setClima] = useState()
    const [ciudad, setCiudad] = useState()
    const [alerta, setAlerta] = useState()
    const [icono, setIcono] = useState()

    
    useEffect(() => {     
        //setInterval(() => {
            console.log("otro intervalo")

            const current_clima = axios.get('https://api.openweathermap.org/data/2.5/weather?id=3860259&units=metric&lang=es&appid=df46a49ff500252abd91350aa2453ef5',{
                method: 'GET'
            })
            .then(resp => {
                console.log(resp);
                setCiudad(resp.data.name)
                setClima(resp.data.main.temp)
                setAlerta(resp.data.weather[0].description)
                setIcono(resp.data.weather[0].icon)
            })
            .catch(error => {
                console.log(error);
            })

        //}, 5000)
            
        }, []);
        
    
    return (
        <div className="clima_widget">
            <p>{ciudad} {clima} °C</p>
            <p className="alerta">{alerta} <img src={"http://openweathermap.org/img/wn/" + icono + ".png"}/></p>
        </div>
    )
}


export default Clima
