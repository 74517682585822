import {createContext} from 'react'


// Contexto: Patrón de diseño de Publicación y Subscripción

const PedidosContext = createContext()


//export const {Consumer,Provider} = Contexto
export default PedidosContext
