import React, {useEffect, useState} from 'react'
import ItemList from './ItemList'
import {useParams} from 'react-router-dom'
import {motion} from 'framer-motion'

const ItemListContainer = ({greeting,listaProductos}) => {

const {id} = useParams()
const [items,setItems] = useState([])

let transition = {duration: 0.6, ease: [0.43, 0.13 , 0.23, 0.96]}

useEffect (()=>{

            if(id){
                const filtros = listaProductos.filter(producto => producto.category_url === id)
                console.log(filtros)
                console.log(id)
                setItems(filtros)
            } else {
                setItems(listaProductos)
            }


},[listaProductos,id])

    return (
        <div className="list">
            <div className="list_container">
                <ItemList productos={items}/>
            </div>
        </div>
    )
}

export default ItemListContainer
