import React from 'react';
import {NavLink} from 'react-router-dom'
import CategoryFilter from './CategoryFilter'
// Componente de tipo Flecha

const NavBarFooter = () => {

  return (
        <ul className="menu">
          <li>
            <CategoryFilter/> 
          </li>
        </ul>
  )
}

  export default NavBarFooter;