import React, {useContext, useEffect, useState} from 'react'
import {motion} from 'framer-motion'
import LoginContext from './LoginContext'
import PedidosContext from './PedidosContext'
import {firestore} from '../firebaseConfig'

const Pedidos = () => {

const { avatar, usuario, ingreso, nombre, email, creacion, login } = useContext(LoginContext)
const { info, pedidos, idActual, setIdActual, editarPedido } = useContext(PedidosContext)

/*
const obtenerPedidos = async () => {
firestore.collection('ordenes').orderBy('date', 'desc').onSnapshot((querySnapshot) => {
    console.log(querySnapshot)
    const docs = [];
    querySnapshot.forEach((doc) => {
        console.log(doc.data());
        console.log(doc.id);
        docs.push({...doc.data(), id:doc.id});
    });
    setInfo(docs);
});
};
*/



    return (
        <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="intro">
            { ingreso === true ? (  
                <div>
                    <h3>Hola {nombre}</h3>
                    <p>Últimos pedidos:</p>
                    <ul className="listado_pedidos">
                    { info.length > 0 ? (  
                    info.map((pedido, i) =>{
                        return (
                            <li key={pedido.id} className={pedido.id} id={i + "_item"}>
                                <div className="pedidos">
                                    <div className="td_2">
                                        <strong>Pedido N° {i + 1}</strong>
                                        <p>{new Date(pedido.date.seconds * 1000).toLocaleDateString("es-ES")}</p>
                                    </div>
                                    <div className="td_5">
                                        <strong>Cliente:</strong>
                                        <p>Nombre: {pedido.buyer.name}</p>
                                        <p>Correo: {pedido.buyer.email}</p>
                                        <p>Teléfono: {pedido.buyer.phone}</p>
                                    </div>
                                    <div className="td_5">
                                        <strong>Productos:</strong>
                                        <p>
                                        {pedido.items.map((item, i) => (
                                            <div key={"producto_" + (i + 1)}>{i + 1}- {item.item.title} - {item.contador} unid.</div>
                                        ))}
                                        </p>
                                    </div>
                                    <div className="td_2">
                                        <strong>Total:</strong> 
                                        <p>u$d {pedido.total}</p>
                                    </div>
                                    <div className="estado_pedido">
                                        <strong>Estado:</strong> 
                                        <div className="edit_estado">                                        
                                        {(() => {
                                            switch (pedido.estado) {
                                                case "instalando": return <p style={{backgroundColor:"blue"}}>{pedido.estado}</p>;
                                                case "entregado": return <p style={{backgroundColor:"darkseagreen"}}>{pedido.estado}</p>;
                                                case "completado": return <p style={{backgroundColor:"green"}}>{pedido.estado}</p>;
                                                case "cancelado": return <p style={{backgroundColor:"red"}}>{pedido.estado}</p>;
                                                default : return <p style={{backgroundColor:"orange"}}>Pendiente</p>;
                                            }
                                        })()}          
                                        <button onClick={() => setIdActual(pedido.id)}>Editar</button>
                                        </div>                                
                                    </div>
                                </div>
                            </li>
                            );
                        })
                    )
                    : (
                        <p className="advertencia">Cargando tus pedidos.</p>
                    )
                    }
                    </ul>
                </div>
                )
                : (
                <button onClick={login}>Ingresar</button>
                )
            }

        </motion.div>
    )
    }

export default Pedidos
