import React, {useContext} from 'react'
import {NavLink} from 'react-router-dom'
import LoginContext from './LoginContext';

const Login = () => {

    const { avatar, usuario, ingreso, nombre, login } = useContext(LoginContext)


    return (
        <div className="icon avatar">
            { ingreso === true ? (  
                <div className="usuario">
                    <span>{nombre}</span>
                    <NavLink to="/mi-cuenta" title="Mi cuenta"><img src={avatar} alt="mi-cuenta"/></NavLink>
                </div>
                )
                : (
                <button onClick={login}>Ingresar</button>
            )
            }
        </div>
    )
}

export default Login
