import React from 'react'

const Hamburger = () => {

    const submenu = [
        {
            name: "blog",
            description: "energía pensada"
        },
        {
            name: "contacto",
            description: "escríbenos"
        },
        {
            name: "proyectos",
            description: ""
        },
        {
            name: "faqs",
            description: "preguntas frecuentes"
        },
        {
            name: "service",
            description: ""
        }
    ]

    return (
        <ul className="haburgerMenu">
            { submenu.map((item) => {
                return (
                    <li key={item.name}>{item.name}</li>
                )
            })
            }
        </ul>
    )
}

export default Hamburger
