import React, {useEffect, useState, useContext} from 'react'
import DolarContext from './DolarContext'
import axios from 'axios'

const Dolar = () => {

    const [iconDolar, setIconDolar] = useState("")
    const { dolar } = useContext(DolarContext)

    const toggleChecked = () => {
        console.log("click")
        setIconDolar(value => !value)
    }

    return (
        <div className="dolar">
            {/*
            <label className="switch">
                <input type="checkbox" onChange={toggleChecked}/>
                <span className={"slider round " + iconDolar}></span>
            </label>
            */}
            {<span>1 u$d - AR$ {dolar}</span>}
        </div>
    )
}

export default Dolar
