import React, {useState,useContext} from 'react'
import ItemCount from './ItemCount'
import bookmark from '../imagenes/bookmark.svg'
import CartContext from './CartContext'
import DolarContext from './DolarContext'
import {motion} from 'framer-motion'

const ItemDetail = ({item,identificador,titulo,precio,imagen,categoria,descripcion,catalogo,stock,url,inicial}) => {
    
    const [mostrar,setMostrar] = useState("")   
	const { addToCart } = useContext(CartContext);

    const { dolar } = useContext(DolarContext)

    let transition = {duration: 0.6, ease: [0.43, 0.13 , 0.23, 0.96]}

    return (
            <div className="item-detalle">
                <div className="slider-img-producto"></div>
                <div className="container-img-producto">
                    <img src={imagen} alt={titulo}/>
                </div>
                <div className="descripcion_producto">
                    <motion.h3
                    initial={{ opacity: 0 }}
                    transition={{delay:0.3, ...transition}}
                    animate={{ opacity: 1 }}
                    >{titulo}</motion.h3>
                    <motion.p
                    initial={{ opacity: 0 }}
                    transition={{delay:0.4, ...transition}}
                    animate={{ opacity: 1 }}
                    >{descripcion}</motion.p>
                    <motion.h4
                    initial={{ opacity: 0 }}
                    transition={{delay:0.5, ...transition}}
                    animate={{ opacity: 1 }}
                    >u$d {precio} - $ {precio * dolar}</motion.h4>
                    <p>Stock disponible: {stock}</p>
                    <div className="detalles-links">
                        <a href={catalogo} title={"catalogo-" + url} target="_blank" rel="noreferrer">Catálogo</a>
                        <span className="bockmark"><img src={bookmark} alt="bookmark" width="20px"/></span>
                    </div>
                    <small>Categoría: {categoria} | ID: {identificador}</small>
                    <small className="advertencia_carrito">{mostrar}</small>
                    <ItemCount precio={precio} item={item} stock={stock} initial={inicial} id={identificador} mostrarAgregar={setMostrar} addToCart={addToCart} />
                </div>
            </div>

    )
}

export default ItemDetail
